import * as Sentry from '@sentry/nextjs';

// ref: https://docs.sentry.io/platforms/javascript/guides/nextjs
export function initSentry() {
  const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

  if (dsn) {
    Sentry.init({
      dsn,
      // Configures the sample rate for error events, in the range of 0.0 to 1.0.
      sampleRate: 1.0,
      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.001, // 0.1%
    });
  }
}
